import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import translationHelper from "../i18n/helper"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import Col from "react-bootstrap/Col"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import { graphql, useStaticQuery } from "gatsby"
import BoxedItem from "../components/Misc/BoxedItem/boxedItem"
import Img from "gatsby-image"
import classes from "../assets/sass/pages/contact.module.scss"
import GMaps from "../components/Contact/GMaps/gMaps"
import SectionSingleVerticalSeperator
  from "../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import ContactForm from "../components/Contact/ContactForm/contactForm"

const Contact = (props) => {

  const { locale } = props.pageContext

  const data = useStaticQuery(graphql`
      query {
          mailContactIcon:file(relativePath: {eq: "icons/mail-contact.png"}) {
              childImageSharp {
                  fixed(width:35) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
              }
          }
          phoneContactIcon:file(relativePath: {eq: "icons/phone-contact.png"}) {
              childImageSharp {
                  fixed(width:35) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
              }
          }
          pinContactIcon:file(relativePath: {eq: "icons/pin-contact.png"}) {
              childImageSharp {
                  fixed(width:35) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
              }
          }
          markerIcon:file(relativePath: {eq: "icons/marker-md.png"}) {
              absolutePath
              relativePath
              relativeDirectory
              childImageSharp {
                  fixed(width:120) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
              }
          }
      }

  `)


  return (
    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={translationHelper[locale]["contactPageTitle"]}
           lang={props.pageContext.locale}
           description={translationHelper[locale]["contactPageTitle"]}
      />
      <Container>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator pixels={40}/>
            <SectionTitleSeperator h1Override={true} hideTop={true} title={translationHelper[locale]["contact"]}/>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className={classes.LeftSideItemsWrapper}>
              <BoxedItem style={{ borderRadius: "4px" }}>
                <a href="https://goo.gl/maps/KP2d2bT5U1a1r7ux8" rel="noopener noreferrer" target="_blank">
                  <div className={classes.WithTextContainer}>
                    <div className={classes.WithIcon}>
                      <Img alt={translationHelper[locale]["pinIconSvg"]}  fixed={data.pinContactIcon.childImageSharp.fixed}/>
                    </div>
                    <div className={classes.WithText}>
                      <h3>{translationHelper[locale]["swissAddress"]}</h3>

                    </div>
                  </div>
                </a>
              </BoxedItem>
              <EmptySpaceSeperator pixels={40}/>
              <BoxedItem style={{ borderRadius: "4px" }}>
                <a href="mailto:info@viewnvisit.ch" rel="noopener noreferrer" target="_blank">
                  <div className={classes.WithTextContainer}>
                    <div className={classes.WithIcon}>
                      <Img alt={translationHelper[locale]["mailIconSvg"]}  fixed={data.mailContactIcon.childImageSharp.fixed}/>
                    </div>
                    <div className={classes.WithText}>
                      <h3>info@viewnvisit.ch</h3>

                    </div>
                  </div>
                </a>
              </BoxedItem>
              <EmptySpaceSeperator pixels={40}/>
              <BoxedItem style={{ borderRadius: "4px" }}>
                <a href="tel:+410225917992" rel="noopener noreferrer" target="_blank">
                  <div className={classes.WithTextContainer}>
                    <div className={classes.WithIcon}>
                      <Img alt={translationHelper[locale]["phoneIconSvg"]}  fixed={data.phoneContactIcon.childImageSharp.fixed}/>
                    </div>
                    <div className={classes.WithText}>
                      <h3>+41 (0) 22 591 79 92</h3>

                    </div>
                  </div>
                </a>
              </BoxedItem>
            </div>

          </Col>

          <Col md={6}>
            <BoxedItem style={{ borderRadius: "4px" }}>
              <GMaps
                height="350px"
                lat={46.2022334}
                lng={6.1473592}
                zoom={13}
                markerText="ViewNVisit - Rue du Purgatoire 3, 1204 Genève"
                icon={data.markerIcon.childImageSharp.fixed.src}
              />
            </BoxedItem>
          </Col>
        </Row>
        <EmptySpaceSeperator/>

        <SectionSingleVerticalSeperator/>
      </Container>

      <Container fluid={true} className="p-0">
        <Row noGutters={true}>
          <Col md={12}>
            <ContactForm formId="contact" />
            <h2 className="h2TitleStyled text-center mainColor p-4">{translationHelper[locale]["weDeliverTheProjectYouNeed"]} </h2>
          </Col>
        </Row>
      </Container>
    </Layout>

  )
}

export default Contact
