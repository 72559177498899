import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import SectionSingleVerticalSeperator
  from "../../../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import EmptySpaceSeperator from "../../../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import { injectIntl } from "react-intl"
import classes from "./contactForm.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import ReCAPTCHA from "react-google-recaptcha"
import parse from "html-react-parser"

const ContactForm = (props) => {

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [formFields, setFormFields] = useState({
    name: "",
    lastName: "",
    phone: "",
    email: "",
    country: "",
    message: "",
    agree: "",
  })

  const recaptchaRef = React.createRef()
  const formRef = React.createRef()
  const { formatMessage } = props.intl



  const data = useStaticQuery(graphql`
      query {
          darkBlueBgImage: file(relativePath: {eq: "bg-images/section-darkblue.png"}) {
              childImageSharp {
                  fluid(maxWidth:4200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }`)

  const onRecaptchaChange = e => {
    console.debug(e);

    if (e) {
      setSubmitDisabled(false)
    }
  }

  const handleChange = e => setFormFields({ ...formFields, [e.target.name]: e.target.value })

  return (
    <BackgroundImage width="100%" fluid={data.darkBlueBgImage.childImageSharp.fluid} backgroundColor="#1f2732">
      <SectionSingleVerticalSeperator color="#fff"/>
      <EmptySpaceSeperator/>
      <h2 className="h2TitleStyled text-center mainColor">{formatMessage({ id: "contactForm" })}</h2>
      <p className="pTextStyled colorLight text-center colorLight">{formatMessage({ id: "contactFormText" })}</p>
      <SectionSingleVerticalSeperator color="#fff"/>
      <EmptySpaceSeperator pixels={40}/>

      <Container>
        <Row>
          <Col md={12}>
            <form  ref={formRef} data-netlify-recaptcha="true" action="/contact-sent" id={props.formId} name={props.formId}
                  method="POST"
                  data-netlify="true"
                  // data-netlify-recaptcha="true"
            >
              <Row>
                <Col md={4}>
                  <input
                    className={classes.Input}
                    type="text" name="name"
                    id="name"
                    placeholder={formatMessage({ id: "name" })}
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <input
                    className={classes.Input}
                    type="text" name="lastName"
                    id="lastName"
                    placeholder={formatMessage({ id: "lastName" })}
                    onChange={handleChange}

                  />
                </Col>
                <Col md={4}>
                  <input
                    className={classes.Input}
                    type="text" name="phone"
                    id="phone"
                    required={true}
                    placeholder={formatMessage({ id: "phone" })}
                    onChange={handleChange}

                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <input
                    className={classes.Input}
                    type="email" name="email"
                    id="email"
                    required={true}
                    placeholder={formatMessage({ id: "email" })}
                    onChange={handleChange}

                  />
                </Col>

                {
                  props.formId === "contact" ?
                    <Col md={6}>
                      <input
                        className={classes.Input}
                        type="text" name="country"
                        id="country"
                        placeholder={formatMessage({ id: "country" })}
                        onChange={handleChange}

                      />
                    </Col>
                    :
                    <Col md={6}>
                      <Form.Control className={classes.Input} as="select">
                        <option value="website">Website</option>
                        <option value="eshop">Eshop</option>
                        <option value="seo-digital">SEO - Digital Marketing</option>
                        <option value="branding">Branding</option>
                        <option value="mobile-app">Mobile Application</option>
                        <option value="custom-solution">Custom Solution</option>
                      </Form.Control>

                    </Col>
                }

              </Row>
              <Row>
                <Col md={12}>
                  <textarea
                    className={classes.Input}
                    placeholder={formatMessage({ id: "yourMessage" })}
                    id="message"
                    rows="6"
                    name="message"
                    onChange={handleChange}

                  />

                </Col>
                {/*<input type="hidden" name="bot-field" id="bot-field"/>*/}
                <input type="hidden" name="form-name" value={props.formId}/>
              </Row>
              <Row>
                <Col md={4}>
                  {/*<div data-netlify-recaptcha="true"/>*/}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    onChange={onRecaptchaChange}
                    sitekey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
                  />
                </Col>
                <Col md={8}>
                  <input type="checkbox"
                         name="agree"
                         value="check"
                         required={true}
                         onChange={handleChange}
                         id="agree"/>


                  <label className={classes.CheckBoxLabel}>
                    {
                      parse(formatMessage({ id: "contactFormConsent" }))
                    }
                  </label>
                </Col>
              </Row>
              <EmptySpaceSeperator/>

              <Row>
                <Col md={12}>
                  <input className={classes.SubmitBtn} disabled={submitDisabled} type="submit" value= {formatMessage({ id: "send" })}/>
                  <EmptySpaceSeperator pixels={40}/>

                </Col>

              </Row>
            </form>
          </Col>
          <EmptySpaceSeperator pixels={40}/>
        </Row>

      </Container>

    </BackgroundImage>
  )
}

export default injectIntl(ContactForm)
